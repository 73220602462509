<template>
  <div class="page">
    <Navbar :title="'优惠券'"/>
    <div class="header">
      <Tabbar :tab='tab' @change="tabChange" />
    </div>
    <div class="page_list" :style="`width: 200%; transform: translateX(${25 - tabIndex * 50}%);`">
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key='index'
          class="list_item">
          <div class="list_item_name">{{item.name}}</div>
          <div class="list_item_date">过期时间：{{item.date}}</div>
          <div class="list_item_line"></div>
          <div class="list_item_gift">
            <div class="list_item_gift_text">优惠券X{{item.num}}</div>
            <div @click="getGift(index)" class="list_item_btn">领取</div>
          </div>
        </div>
        <NoData v-if="list.length > 0&&listNoMore" title="暂无更多优惠券~" />
        <NoData v-if="list.length == 0" title="暂无优惠券~" />
      </div>
      <div class="list">
        <div
          v-for="(item, index) in list2"
          :key='index'
          class="list_item">
          <div class="list_item_name">{{item.name}}</div>
          <div class="list_item_date">过期时间：{{item.date}}</div>
          <div class="list_item_line"></div>
          <div class="list_item_gift">
            <div class="list_item_gift_text">优惠券X{{item.num}}</div>
          </div>
        </div>
        <NoData v-if="list2.length > 0&&listNoMore2" title="暂无更多优惠券~" />
        <NoData v-if="list2.length == 0" title="暂无优惠券~" />
      </div>
    </div>
  </div>
</template>
<script>
import Tabbar from '@/components/tabbar.vue'
export default {
  components: {
    Tabbar
  },
  data(){
    return {
      tabIndex: 0,
      tab: [
        {name: '可领取', check: true},
        {name: '可使用', check: false}
      ],
      list: [ // 可领取
        {name: '满10000减100', date: '2029-01-02 ', num: 1},
        {name: '满14000送1010', date: '2029-01-02 ', num: 2},
        {name: '满20000送1020', date: '2029-01-02 ', num: 1},
        {name: '满70000送1080', date: '2029-01-02 ', num: 2},
        {name: '满10000送100', date: '2029-01-02 ', num: 1},
        {name: '满180000送1090', date: '2025-01-02 ', num: 1},
        {name: '满155000送1070', date: '2025-01-02 ', num: 1},
        {name: '满1022000送1070', date: '2025-01-02 ', num: 1},
        {name: '满108800000送10880', date: '2025-01-02 ', num: 1},
      ],
      listNoMore: true, // 是否还有更多
      list2: [ // 可使用
        {name: '满10000减100', date: '2029-01-02 ', num: 1},
        {name: '满10000送100', date: '2029-01-02 ', num: 2}
      ],
      listNoMore2: true, // 是否还有更多
    }
  },
  mounted () {
    console.log(this.$t("btn.confirm"));
  },
  methods: {
    tabChange (e) {
      this.tabIndex = e
    },
    getGift (key) {
      this.$Msg('领取成功，请前往可使用优惠券进行查看')
      this.list2.push(this.list[key])
      this.list.splice(key, 1)
    }
  }
}
</script>
<style scoped lang='scss'>
.page{
  background-color: #f0f0f0;
}
.header{
  width: 100%;
  background-color: #fff;
}
.page_list{
  display: flex;
  overflow: hidden;
  transition: .3s;
  flex: 1;
}
.list{
  padding: .3rem .2rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.list_item{
  margin-bottom: .15rem;
  padding: 0 .15rem;
  width: 100%;
  background-color: #fff;
  border-radius: .15rem;
  box-sizing: border-box;
}
.list_item_name{
  padding: .2rem 0;
  font-weight: bold;
  color: $color-primary;
}
.list_item_date{
  font-size: .2rem;
  color: $color-primary;
}
.list_item_line{
  margin: .2rem 0;
  flex: 1;
  height: 1px;
  background: linear-gradient(to right, #ddd, #ddd 5px, transparent 5px, transparent);
  background-size: 15px 100%;
}
.list_item_gift{
  display: flex;
  align-items: center;
  padding: .1rem 0;
}

.list_item_gift_text{
  flex: 1;
  font-size: .22rem;
  color: #8d8d8d;
}
.list_item_btn{
  padding: 0 .3rem;
  line-height: .4rem;
  font-size: .22rem;
  background-color: $color-primary;
  color: #fff;
  border-radius: .2rem;
}
</style>